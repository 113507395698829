import axios from 'axios'
import store from '@/store'

export const postTestStat = (params) => {
  return axios.post(`${store.state.baseAPI}/stats`, params)
}

export const getTestStatSummary = (params) => {
  return axios.get(`${store.state.baseAPI}/stats/summary?username=${params.username}&startDate=${params.startDate}T00:00:00.0Z&endDate=${params.endDate}T23:59:59.0Z`)
}

export const getTestStatHistory = (params) => {
  return axios.get(`${store.state.baseAPI}/stats/query?username=${params.username}&startDate=${params.startDate}T00:00:00.0Z&endDate=${params.endDate}T23:59:59.0Z&subjectIndex=${params.subjectIndex}`)
}

export const postRealTestStat = (params) => {
  return axios.post(`${store.state.baseAPI}/stats/realtest`, params)
}

export const getRealTestStatSummary = (params) => {
  return axios.get(`${store.state.baseAPI}/stats/realtest/summary?username=${params.username}&roundTest=${params.roundTest}`)
}

export const getRealTestStatHistory = (params) => {
  return axios.get(`${store.state.baseAPI}/stats/realtest/query?username=${params.username}&roundTest=${params.roundTest}`)
}

export const getRoundRealTest = (params) =>{
  return axios.get(`${store.state.baseAPI}/stats/realtest/round_test?username=${params.username}&startDate=${params.startDate}T00:00:00.0Z&endDate=${params.endDate}T23:59:59.0Z`)
}

export const getRealTestsummarySubject = (params) =>{
  return axios.get(`${store.state.baseAPI}/stats/realtest/summary_subject?username=${params.username}&roundTest=${params.roundTest}`)
}

export const postLogin = (params) => {
  return axios.post(`${store.state.baseAPI}/users/login`, {}, {
    headers: {
      'Authorization': `Basic ${params}`
    }
  })
}

export const postRegister = (params) => {
  return axios.post(`${store.state.baseAPI}/users/register`, params)
}

export const postForgotPassword = (params) => {
  return axios.post(`${store.state.baseAPI}/users/forgotpassword`, params)
}

export const postResetPassword = (params) => {
  return axios.post(`${store.state.baseAPI}/users/resetpassword`, params)
}

export const getLogin = (params) => {
  return axios.get(`${store.state.baseAPI}/users/login`, {
    headers: {
      'x-access-token': `${params}`
    }
  })
}

export const getVersion = () => {
  return axios.get(`${store.state.baseAPI}`, {})
}

export const postCharge = (params, token) => {
  return axios.post(`${store.state.baseAPI}/users/charge`, params, { headers: { 'x-access-token': `${token}` } })
}

export const postChargeEmail = (params, token) => {
  return axios.post(`${store.state.baseAPI}/users/charge_email`, params)
}

export const postDepartment = (params) => {
  return axios.post(`${store.state.baseAPI}/users/department`, params)
}

export const postPayment = (params, token) => {
  return axios.post(`${store.state.baseAPI}/users/payment`, params, { headers: { 'x-access-token': `${token}` } })
}

export const postTime = (params, token) => {
  return axios.post(`${store.state.baseAPI}/users/time`, params, { headers: { 'x-access-token': `${token}` } })
}

export const updateStatusPayment = (paymentID, params, token) => {
  return axios.patch(`${store.state.baseAPI}/users/payment/${paymentID}`, params, { headers: { 'x-access-token': `${token}` } })
}

// export const getCheckCode = (params, token) => {
//   return axios.get(`${store.state.baseAPI}/users/code?code_id=${params}`, {
//     headers: {
//       'x-access-token': `${token}`
//     }
//   })
// }

export const getCheckCode = (params) => {
  return axios.get(`${store.state.baseAPI}/users/code?code_id=${params}`)
}

export const getActivateCode = (params, token) => {
  return axios.get(`${store.state.baseAPI}/users/activate_code?code_id=${params}`, { headers: { 'x-access-token': `${token}` } })
}

// export const postActivate = (token) => {
//   return axios.post(`${store.state.baseAPI}/users/activate`, {
//     headers: {
//       'x-access-token': `${token}`
//     }
//   })
// }

export const postActivate = (params) => {
  return axios.post(`${store.state.baseAPI}/users/activate`, params)
}

export const postClick = (params) => {
  return axios.post(`${store.state.baseAPI}/users/click`, params)
}