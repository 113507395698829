<template>
  <nav v-if="!(realtest.state === 2)" class="
      navbar navbar-vertical
      fixed-left
      navbar-expand-md navbar-light
      bg-white
    " id="sidenav-main" style="position: fixed; top: 0; width: 100%; z-index: 999">
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar"> </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="..." />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <!-- <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
            <a
              slot="title-container"
              class="nav-link nav-link-icon"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ni ni-bell-55"></i>
            </a>
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </base-dropdown> -->
          <div v-if="$route.name">
            <label style="color: black" class="mr-2"><b>{{ $store.state.currect }}/{{
              $store.state.click
                }}</b></label>
            <!-- <a href="/campaign"
              ><b-badge
                variant="danger"
                class="badge-circle badge-md badge-floating border-white"
                >1<i class="ni ni-bell-55"></i></b-badge
            ></a> -->
            <base-button size="sm" icon type="primary" style="border-radius: 50%"
              @click="$store.state.goToTestNumber = true">
              <span class="btn-inner--icon"><i class="ni ni-bold-down"></i></span>
            </base-button>
          </div>
          <div v-else></div>
          <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
            <a slot="title-container" class="nav-link" href="#" role="button">
              <div class="media align-items-center">
                <span class="avatar avatar-sm rounded-circle">
                  <img alt="Image placeholder" src="@/assets/en.webp" />
                </span>
              </div>
            </a>
            <router-link to="/register" class="dropdown-item">
              <i class="ni ni-circle-08 text-orange"></i>
              <span>สมัครสมาชิกใหม่</span>
            </router-link>

            <!-- <router-link to="/campaign" class="dropdown-item">
              <i class="ni ni-favourite-28 text-orange"></i>
              <span>แคมเปญ1</span>
            </router-link> -->

            <!-- <div class="dropdown-header noti-title">
              <a href="https://neighborsoft.com/" class="text-overflow m-0">
                
              </a>
            </div> -->
            <!-- <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link>
            <div class="dropdown-divider"></div> -->
            <b-dropdown-item href="/login" @click="logout()">
              <i class="ni ni-user-run"></i>
              <span v-if="$store.state.username">Logout</span>
              <span v-else>login</span>
            </b-dropdown-item>
            <hr />
            <a href="https://neighborsoft.com/" class="dropdown-item">
              <i class="ni ni-single-02 text-primary"></i>
              <span>พัฒนาโดย NeighborSoft</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
            </div>
          </div>
        </div>


        <div :style="'position: relative; overflow-y: scroll; height: ' +
          (windowHeight + 200) +
          'px'
          ">

          <ul class="navbar-nav">
            <slot name="links"> </slot>
          </ul>
          <!--Divider-->
          <hr class="my-3" />
          <!-- {{ $store.state.time_total }} -->

          <div v-if="$route.name == 'ทดสอบ' || $route.name == 'ข้อสอบ'">
            <label style="color: black"><b>คะแนน: {{ $store.state.currect }}/{{
              $store.state.click
                }}</b></label>
            <base-input v-if="$route.name == 'ทดสอบ'">
              <b-form-checkbox class="mb--3" v-model="$store.state.checked_solve" name="check-button" switch>
                <span class="ml--3">เฉลยละเอียด</span></b-form-checkbox>
            </base-input>
            <base-input label="ไปข้อ">
              <b-input-group size="sm" class="mb--3">
                <b-form-input size="sm" type="number" min="0.00" v-model="nextIndex"></b-form-input>
                <b-input-group-prepend>
                  <!-- <b-button variant="outline-primary">ไป</b-button> -->
                  <b-button variant="outline-primary" @click="$store.state.nextIndex = nextIndex">
                    <i class="ni ni-bold-right"></i>
                  </b-button>
                  <b-button variant="outline-primary" @click="$store.state.goToTestNumber = true">
                    ล่าสุด
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </base-input>

            <!-- {{$store.state.nextIndex}} -->
            <base-input label="เลือกหมวดหมู่">
              <select class="form-control mb--3" v-model="$store.state.topic_index">
                <option v-for="(t, index) in $store.state.topic_names" :key="index" :value="index">
                  {{ t.text }}
                </option>
              </select>
            </base-input>
            <base-input label="เลือกวิชา" class="">
              <select class="form-control" v-model="$store.state.subject_index">
                <option v-for="(t, index) in $store.state.subject_names" :key="index" :value="t.value">
                  {{ t.text }}
                </option>
              </select>
            </base-input>
          </div>

        </div>
        <div v-if="$route.name == 'ทดสอบอัลกอริธึม'">
          <label style="color: black"><b>คะแนน: {{ $store.state.currect }}/{{
            $store.state.click
              }}</b></label>
          <div>เวลาครั้งที่ 1: {{ $store.state.impact.time[0] }}</div>
          <div>คะแนนครั้งที่ 1: {{ $store.state.impact.score[0] }}</div>
          <div>เวลาครั้งที่ 2: {{ $store.state.impact.time[1] }}</div>
          <div>คะแนนครั้งที่ 2: {{ $store.state.impact.score[1] }}</div>
          <div>เวลาครั้งที่ 3: {{ $store.state.impact.time[2] }}</div>
          <div>คะแนนครั้งที่ 3: {{ $store.state.impact.score[2] }}</div>
          <br />
          <div>สอบจริงแบบเรียง: {{ $store.state.impact.score_test1 }}</div>
          <br />
          <div>เวลารวม: {{ $store.state.impact.totaltime }}</div>
          <div>คะแนนครั้งที่ 4: {{ $store.state.impact.score_random }}</div>
          <br />
          <div>สอบจริงแบบสุ่ม: {{ $store.state.impact.score_test2 }}</div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import platform from "platform-detect";
import NavbarToggleButton from "@/components/NavbarToggleButton";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      nextIndex: 0,
      windowHeight: 0,
    };
  },
  props: {
    logo: {
      type: String,
      default: require("@/assets/logo.webp"),
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    handleResize() {
      this.windowHeight = window.innerHeight - 400;
    },
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    logout() {
      //   this.$store.state.username = "";
      //   this.$cookie.delete("Token");
      //   var e = this.$parent.$parent;
      //   e.checktoken();
      this.$store.commit("LOGOUT");
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  computed: {
    platform() {
      return platform;
    },
    realtest() {
      return this.$store.state.realtest;
    },
  },
};
</script>
