<template>
  <div class="main-content" style="background-color: #8f384d">
    <!-- <ads-login></ads-login> -->
    <base-nav
      v-model="showMenu"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main navbar-top navbar-white"
      expand="lg"
    >
      <div slot="brand" class="navbar-wrapper">
        <b-navbar-brand to="/">
          <img :src="logo" />
        </b-navbar-brand>
      </div>

      <template>
        <div class="navbar-collapse-header">
          <b-row>
            <b-col cols="6" class="collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </b-col>
            <b-col cols="6" class="collapse-close">
              <button
                type="button"
                class="navbar-toggler"
                @click="showMenu = false"
              >
                <span></span>
                <span></span>
              </button>
            </b-col>
          </b-row>
        </div>
        <b-navbar-nav class="align-items-lg-center ml-lg-auto">
          <!-- <b-nav-item to="/dashboard">
            <i class="ni ni-planet"></i>
            <span class="nav-link-inner--text">Dashboard</span>
          </b-nav-item> -->
          <b-nav-item to="/register">
            <i class="ni ni-circle-08" style="color: #e97878"></i>
            <span class="nav-link-inner--text" style="color: #9b5151"
              >สมัครสมาชิกใหม่</span
            >
          </b-nav-item>
          <b-nav-item to="/login">
            <i class="ni ni-key-25" style="color: #e97878"></i>
            <span class="nav-link-inner--text" style="color: #9b5151"
              >เข้าสู่ระบบ</span
            >
          </b-nav-item>
          <b-nav-item to="/pricing">
            <i class="ni ni-box-2" style="color: #e97878"></i>
            <span class="nav-link-inner--text" style="color: #9b5151"
              >ซื้อ VOUCHER</span
            >
          </b-nav-item>
          <!-- <b-nav-item to="/profile">
            <i class="ni ni-single-02"></i>
            <span class="nav-link-inner--text">Profile</span>
          </b-nav-item> -->
        </b-navbar-nav>
      </template>
    </base-nav>

    <div class="main-content">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <footer class="py-5" id="footer-main">
      <b-container>
        <b-row align-v="center" class="justify-content-xl-between">
          <b-col xl="6">
            <div class="copyright text-center text-xl-left text-muted">
              <span style="color: #ffee93">© {{ year }}</span>
              <a
                href="https://neighborsoft.com/"
                class="font-weight-bold ml-1"
                target="_blank"
              >
                <span style="color: #ffee93">NeighborSoft</span>
              </a>
            </div>
            <div style="color: #ffee93">
              <!-- 123/2007 Moo 16 Mittapap Rd., Nai-Muang, Muang District, Khon Kaen
              40002,Thailand, 0837278422 -->
              152 Moo 11, Ban Wiang Kasem, Muang Det,  Det Udom, Ubon Ratchathani, 34160, Thailand, 0943751375
            </div>
          </b-col>
          <b-col xl="6" class="col-xl-6">
            <b-nav
              class="nav-footer justify-content-center justify-content-xl-end"
            >
              <b-nav-item
                href="https://www.facebook.com/EnGeniusTH"
                target="_blank"
              >
                <span style="color: #ffee93">Facebook Page</span>
              </b-nav-item>
              <b-nav-item @click="$router.push('privacypolicy')">
                <span style="color: #ffee93">Privacy policy</span>
              </b-nav-item>
              <b-nav-item @click="$bvToast.show('refund-toast')">
                <span style="color: #ffee93">Refund policy</span>
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
        <b-row>
          <b-toast
            id="refund-toast"
            ref="refund-toast"
            class="p-3 bg-secondary progress-bar-striped m-3"
            no-auto-hide
            static
          >
            Engenius subscriptions is billed in advance on a monthly or annual
            basis and is non-refundable; no refunds will be issued. Engenius
            does not offer prorated refunds for canceled subscription plans.
            There will be no refunds or credits for partial months of service,
            upgrade/downgrade refunds, or refunds for months unused with an open
            account. In order to treat everyone equally, no exceptions will be
            made.
          </b-toast>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>
import { BaseNav } from "@/components";
import { ZoomCenterTransition } from "vue2-transitions";
// import AdsLogin from "./AdsLogin.vue";

export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
    // AdsLogin,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      logo: require("@/assets/logo.webp"),
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.style = "background-color: #8f384d";
      //   document.body.classList.add("bg-default");
      //   document.body.styleList.add("background-color: #8f384d;");
      //   document.body.style.background = "#8f384d";
    },
    removeBackgroundColor() {
      //   document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeDestroy() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        // this.updateBackground();
      },
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
