import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";

import NotFound from "@/views/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    redirect: "login",
    component: DashboardLayout,
    children: [
      {
        path: "/profile",
        name: "โปรไฟล์",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Pages/Profile.vue"),
      },
      // {
      //     path: '/dashboard2',
      //     name: 'สถิติ2',
      //     // route level code-splitting
      //     // this generates a separate chunk (about.[hash].js) for this route
      //     // which is lazy-loaded when the route is visited.
      //     component: () => import(/* webpackPrefetch: "true" */ '../views/Pages/Stat2.vue')
      // },
      {
        path: "/icons",
        name: "icons",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Icons.vue"),
      },
      // {
      //   path: '/question',
      //   name: 'ข้อสอบ',
      //   component: () =>
      //     import( /* webpackPrefetch: "true" */ '../views/Pages/TestNoSolve.vue')
      // },
      // {
      //     path: '/test10',
      //     name: 'ทดสอบ 10 ข้อ',
      //     component: () =>
      //         import ( /* webpackPrefetch: "true" */ '../views/Pages/Test10.vue')
      // },
      {
        path: "/test",
        name: "ทดสอบ",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Pages/Test.vue"),
      },
      // {
      //     path: '/test2/:id',
      //     name: 'ทดสอบ',
      //     component: () =>
      //         import ( /* webpackPrefetch: "true" */ '../views/Pages/Test2.vue')
      // },
      // {
      //     path: '/impact',
      //     name: 'ทดสอบอัลกอริธึม',
      //     component: () => import(/* webpackPrefetch: "true" */ '../views/Pages/Impact.vue')
      // },
      {
        path: "/realtest",
        name: "สอบจริง",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Pages/RealTest.vue"),
      },
      {
        path: "/download",
        name: "โหลดลงมือถือ",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Pages/Download.vue"),
      },
      // {
      //     path: '/remember',
      //     name: 'จดจำ',
      //     component: () =>
      //         import ( /* webpackPrefetch: "true" */ '../views/Pages/Remember.vue')
      // },
      {
        path: "/campaign",
        name: "แคมเปญ",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Pages/Campaign.vue"),
      },
      // {
      //     path: '/premium',
      //     name: 'Premium',
      //     component: () =>
      //         import ( /* webpackPrefetch: "true" */ '../views/Pages/Premium.vue')
      // },
      // {
      //     path: '/maps',
      //     name: 'maps',
      //     component: () =>
      //         import ( /* webpackPrefetch: "true" */ '../views/GoogleMaps.vue')
      // },
      // {
      //     path: '/tables',
      //     name: 'tables',
      //     component: () => import(/* webpackPrefetch: "true" */ '../views/RegularTables.vue')
      // },
      {
        path: "/stats",
        name: "สถิติ",
        component: () =>
          import(/* webpackPrefetch: "true" */ "@/views/Stat/Userstat2.vue"),
      },
      {
        path: "/history/:test_id",
        name: "เฉลย",
        component: () =>
          import(/* webpackPrefetch: "true" */ "@/views/Stat/History.vue"),
      },
      {
        path: "/summary/:test_id",
        name: "สรุปผลสอบ",
        component: () =>
          import(/* webpackPrefetch: "true" */ "@/views/Pages/Summary.vue"),
      },
      {
        path: "/pricing",
        name: "พรีเมี่ยม",
        component: () =>
          import(/* webpackPrefetch: "true" */ "@/views/Pages/Purchase.vue"),
      },
      {
        path: "/blogs",
        name: "คำถามยอดฮิต",
        component: () =>
          import(/* webpackPrefetch: "true" */ "@/views/Pages/Blog.vue"),
      },
      {
        path: "/video",
        name: "วีดีโอทบทวน",
        component: () =>
          import(/* webpackPrefetch: "true" */ "@/views/Pages/Video.vue"),
      },
      {
        path: "/sheet",
        name: "ชีทสรุป",
        component: () =>
          import(/* webpackPrefetch: "true" */ "@/views/Pages/Sheet.vue"),
      },
      {
        path: "/undefined",
        redirect: "profile",
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      // {
      //   path: '/home',
      //   name: 'home',
      //   component: () =>
      //     import( /* webpackPrefetch: "true" */ '../views/Pages/Home.vue')
      // },
      {
        path: "/login",
        name: "login",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Pages/Login.vue"),
      },
      {
        path: "/register",
        name: "register",
        component: () =>
          import(/* webpackPrefetch: "true" */ "../views/Pages/Register.vue"),
      },
      {
        path: "/forgotpassword",
        name: "forgotpassword",
        component: () =>
          import(
            /* webpackPrefetch: "true" */ "../views/Pages/ForgotPassword.vue"
          ),
        children: [
          {
            path: ":id",
            name: "forgotpassword2",
            component: () =>
              import(
                /* webpackPrefetch: "true" */ "../views/Pages/ForgotPassword.vue"
              ),
          },
        ],
      },
      {
        path: "/privacypolicy",
        name: "privacypolicy",
        component: () =>
          import(
            /* webpackPrefetch: "true" */ "../views/Pages/PrivacyPolicy.vue"
          ),
      },
      {
        path: "/refundpolicy",
        name: "refundpolicy",
        component: () =>
          import(
            /* webpackPrefetch: "true" */ "../views/Pages/RefundPolicy.vue"
          ),
      },
      { path: "*", component: NotFound },
    ],
  },
];

export default routes;
