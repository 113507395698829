<template>
  <div>
    <vue-internet-checker @status="status" @event="event" />
    <b-toast id="example-toast" ref="example-toast" class="p-3 bg-secondary progress-bar-striped m-3" v-show="toastShow"
      @hide="toastHide" no-auto-hide static style="
        border: 0px;
        padding: 0;
        overflow: hidden;
        position: fixed;
        top: 0px;
        z-index: 997;
      ">
      เว็บไซต์นี้ มีการเก็บข้อมูลการใช้งานเว็บไซต์ (Cookies)
      เพื่อมอบบริการที่ดีที่สุดสำหรับคุณ โดยการเข้าใช้งานเว็บไซต์
      ถือเป็นการยอมรับใน <a href="/privacypolicy">เงื่อนไขการใช้งานเว็บไซต์</a>
    </b-toast>
    <b-modal ref="modal_alert" hide-footer hide-header style="
        max-width: 100%;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
      ">
      <b-alert style="
          max-width: 100%;
          margin: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
        " class="text-center" :show="dismissCountDown" dismissible :variant="msgtype" @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"><strong>{{ msgAlert }}</strong></b-alert>
    </b-modal>
    <div v-if="$store.state.onLine">
      <div class="text-center" v-if="
        $store.state.version_current < $store.state.version ||
        $store.state.version === null
      " style="
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -150px;
          margin-left: -150px;
          width: 300px;
          height: 300px;
        ">
        <!-- <h2>นับถอยหลัง</h2>
        <h1>{{ time_count_down }}</h1>
        <h2>เตรียมพบกับ</h2>
        <h2>EnGenius Version ล่าสุด !!!</h2> -->

        Version ปัจจุบัน {{ $store.state.version_current }} <br />
        Version ใหม่ {{ $store.state.version }} <br />
        <span style="font-size: 17px"><b>กรุณาอัปเดท Version</b></span><br />
        <b-button variant="outline-primary" class="text-left mb-1" @click="reloadPage">Click</b-button>
      </div>
      <div v-else>
        <router-view></router-view>
      </div>
    </div>
    <div v-else>
      <div class="text-center" style="
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -150px;
          margin-left: -150px;
          width: 300px;
          height: 300px;
        ">
        ไม่มีการเชื่อมต่ออินเทอร์เน็ต <br />
        ลองทำตามขั้นตอนเหล่านี้เพื่อเชื่อมต่ออินเทอร์เน็ตอีกครั้ง <br />
        <span style="font-size: 17px"><b>ตรวจสอบโมเด็มและเราเตอร์ของคุณ</b></span><br />
        <span style="font-size: 17px"><b>เชื่อมต่อกับ Wi-Fi อีกครั้ง</b></span><br />
        <b-button variant="outline-primary" class="text-left mb-1" @click="reloadPage">โหลดเว็บอีกครั้ง</b-button>
      </div>
    </div>
  </div>
</template>


<script>
import { detectAnyAdblocker } from "just-detect-adblock";
import vueInternetChecker from "vue-internet-checker";
import moment from "moment";
detectAnyAdblocker().then((detected) => {
  if (detected) {
    // console.log(detected);
    alert("กรุณาปิด Ads Block ><");
    // location.reload();
    window.location = "https://www.facebook.com/EnGeniusTH";
  }
});

export default {
  components: {
    vueInternetChecker,
  },
  data: function () {
    return {
      toastShow: false,
      msgtype: "danger",
      msgAlert: "",
      dismissSecs: 2,
      dismissCountDown: 0,
      interval: null,
      time_count_down: "",
    };
  },
  created() { },
  mounted() {
    // this.$moment().locale("th");
    // this.$moment.tz.setDefault("Asia/Thainland");
    // this.checktoken();

    // this.toast = localStorage.toast;

    // this.$refs.btnHide.click()

    this.fetchTopic();

    this.$store.commit("AUTHEN");
    this.$store.commit("GET_VERSION");

    Omise.setPublicKey(this.$store.state.publicKey);

    OmiseCard.attach();

    this.timerRun();
  },
  watch: {
    "$store.state.topic_index": function (val) {
      if (
        this.$store.state.username === "" ||
        val == 0 || val == 10 || val == 11 ||
        parseInt(this.$store.state.department) + 1 == val
      ) {
        if (val == 11 && !this.$store.state.userPackage.subject.includes(999)) {
          this.$store.commit("SHOW_ALERT", { msg: "กรุณาซื้อชีทสรุปอบรม" });
          return;
        }
        var arr = this.$store.state.topic[val].list;
        this.$store.state.subject_names = [];
        var temp = [];
        for (let i = 0; i < arr.length; i++) {
          temp.push({
            value: arr[i].index,
            text: arr[i].namegroup + " " + arr[i].subject,
          });
        }
        this.$store.state.subject_names = temp;
        this.$store.state.subject_index = arr[0].index;
        if (this.$store.state.username) localStorage.topic_index = val;
      } else {
        this.$store.commit("SHOW_ALERT", { msg: "กรุณาเลือกสาขาตัวเอง" });
      }
    },
    "$store.state.subject_index": function (val) {
      if (this.$store.state.username) localStorage.subject_index = val;
      this.$store.commit("RELOAD_SUBJECT");
    },
    "$store.state.showAlert": function (val) {
      if (val) {
        this.showAlert();
        this.$store.state.showAlert = false;
      }
    },
  },
  methods: {
    countdown() {
      var a = moment("20210909 09:09", "YYYYMMDD hh:mm");
      var duration = moment.duration(a.diff(moment()));
      var hours = duration.hours();
      var minutes = duration.minutes();
      var seconds = duration.seconds();
      this.time_count_down =
        this.pad2(hours) + ":" + this.pad2(minutes) + ":" + this.pad2(seconds);
      // console.log(this.$route.name);
    },
    cal_remain() {
      var name = this.$route.name;
      // this.$store.state.time2 = new Date();
      if (name == "ทดสอบ" || name == "สอบจริง") {
        // this.$store.state.time_total = parseInt(
        //   (this.$store.state.time2.getTime() -
        //     this.$store.state.time1.getTime()) /
        //     1000
        // );
        this.$store.state.time_total++;
        if (this.$store.state.time_total % 11 == 0) {
          this.$store.commit("POST_TIME");
        }
      }
    },
    pad2(s) {
      return s.toString().padStart(2, "0");
    },
    timerRun() {
      // this.$store.state.time1 = new Date();
      this.interval = setInterval(this.cal_remain, 1000);
    },
    reloadPage() {
      window.location.reload();
    },
    status(ele) {
      // console.log(ele);
      this.$store.state.onLine = ele;
    },
    event(ele) {
      // console.log(ele);
    },
    fetchTopic() {
      this.$store.state.topic = this.$store.state.detail_topic;
      var topic = this.$store.state.detail_topic;
      this.$store.state.topic_names = [];
      this.$store.state.department_names = [];
      this.$store.state.department_names_2 = [];
      for (let i = 0; i < topic.length; i++) {
        this.$store.state.topic_names.push({
          value: i,
          text: topic[i].mode2,
        });
        if (i > 0) {
          this.$store.state.department_names.push({
            value: i,
            text: topic[i].mode2,
          });
          if (i <= 9) {
            this.$store.state.department_names_2.push({
              value: i,
              text: topic[i].mode2,
            });
          }
        }
        var topic_index = localStorage.topic_index;
        if (!topic_index) {
          localStorage.topic_index = 0;
          topic_index = 0;
        }
        this.$store.state.topic_index = topic_index;
      }
    },
    toastHide() {
      this.toastShow = false;
      this.$cookie.set("toastShow", false);
    },
    countDownChanged(dismissCountDown) {
      this.$store.state.dismissCountDown = dismissCountDown;
      if (dismissCountDown == 0) {
        this.$refs.modal_alert.hide();
        this.$store.state.showAlert = false;
      }
    },
    showAlert() {
      this.msgtype =
        this.$store.state.msgtype == null
          ? "danger"
          : this.$store.state.msgtype;
      this.msgAlert = this.$store.state.msgAlert;
      this.dismissCountDown = this.dismissSecs;
      this.$refs.modal_alert.show();
    },
  },
  computed: {
    userPackage() {
      return this.$store.state.userPackage;
    },
    packageDisable() {
      return this.userPackage.exp
        ? moment(this.userPackage.exp).isAfter(moment())
        : false;
    },
  },
};
</script>