var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.$store.state.username)?_c('sidebar-item',{attrs:{"link":{
          name: 'โปรไฟล์',
          path: '/profile',
          icon: 'ni ni-circle-08 text-black',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'ทดสอบ',
          path: '/test',
          icon: 'ni ni-book-bookmark text-primary',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'ซ้อมสอบจริง',
          path: '/realtest',
          icon: 'ni ni-watch-time text-blue',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'สถิติ',
          path: '/stats',
          icon: 'ni ni-chart-pie-35 text-yellow',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'ชีทสรุป',
          path: '/sheet',
          icon: 'ni ni-books text-green',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'วีดีโอทบทวน',
          path: '/video',
          icon: 'ni ni-button-play text-green',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'พรีเมี่ยม',
          path: '/pricing',
          icon: 'ni ni-atom text-pink',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'คำถามยอดฮิต',
          path: '/blogs',
          icon: 'ni ni-air-baloon text-orange',
        }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3"})])],2),_c('div',{staticClass:"main-content"},[(_vm.$route.name != 'สอบจริง')?_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }