/*!

=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import './plugins/axios'
import DashboardPlugin from './plugins/dashboard-plugin';
import VueFab from 'vue-float-action-button'
import VueFacebookPixel from 'vue-facebook-pixel'

// import axios from 'axios'
// import VueAxios from 'vue-axios'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueCookie from 'vue-cookie';
import App from './App.vue';
import Vue2TouchEvents from 'vue2-touch-events'
// import VueFbCustomerChat from 'vue-fb-customer-chat'
import Notifications from 'vue-notification'
import VueTour from 'vue-tour'
import VueProgressiveImage from 'vue-progressive-image'
import VueEasyLightbox from 'vue-easy-lightbox'
require('vue-tour/dist/vue-tour.css')
Vue.use(VueEasyLightbox)
Vue.use(Vue2TouchEvents)
Vue.use(Notifications)
Vue.use(VueProgressiveImage)

import Ads from 'vue-google-adsense'
// Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
// Vue.use(Ads.InFeedAdsense)
// Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-7712989399815930' })

Vue.use(VueTour)

Vue.use(VueCookie);
// import firebase from 'firebase/app'
// const config = {
//     apiKey: "AIzaSyDaOX-CTNcHQTN3eUntsYUwzxplaoI0t7E",
//     authDomain: "engenius21.firebaseapp.com",
//     projectId: "engenius21",
//     storageBucket: "engenius21.appspot.com",
//     messagingSenderId: "890306039086",
//     appId: "1:890306039086:web:46ad0110f79df284e5bebf",
//     measurementId: "G-6XTFMJVL4M"
// };
// firebase.initializeApp(config)
Vue.use(VueFab)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

Vue.use(require('vue-script2'))

// Vue.use(VueFbCustomerChat, {
//     page_id: 101849431791915, //  change 'null' to your Facebook Page ID,
//     theme_color: '#8f384d', // theme color in HEX
//     locale: 'th_TH', // default 'en_US'
// })

Vue.component('vueInternetChecker', require('vue-internet-checker'));

// router setup
import router from './routes/router';

import store from './store'
import './registerServiceWorker'
// plugin setup
Vue.use(DashboardPlugin);
// Vue.use(VueAxios, axios);
Vue.use(VueFacebookPixel)
Vue.analytics.fbq.init('115452787110046', {
    em: 'gift.suthinee@gmail.com'
})
Vue.analytics.fbq.event('ViewContent', {
    content_name: 'Really Fast Running Shoes'
})

import VueAnalytics from 'vue-analytics';
Vue.use(VueAnalytics, {
    id: 'G-6XTFMJVL4M',
    router
})

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import VueExpandableImage from 'vue-expandable-image'
Vue.use(VueExpandableImage)

import loader from "vue-ui-preloader";
Vue.use(loader);

/* eslint-disable no-new */
new Vue({
    el: '#app',
    render: h => h(App),
    store,
    router
});