<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          v-if="$store.state.username"
          :link="{
            name: 'โปรไฟล์',
            path: '/profile',
            icon: 'ni ni-circle-08 text-black',
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: 'ข้อสอบ',
            path: '/question',
            icon: 'ni ni-books text-orange',
          }"
        ></sidebar-item> -->

        <!-- <sidebar-item
          v-if="!$store.state.username"
          :link="{
            name: 'ทดสอบ 10 ข้อ',
            path: '/test10',
            icon: 'ni ni-book-bookmark text-black',
          }"
        >
        </sidebar-item> -->

        <sidebar-item
          :link="{
            name: 'ทดสอบ',
            path: '/test',
            icon: 'ni ni-book-bookmark text-primary',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'ซ้อมสอบจริง',
            path: '/realtest',
            icon: 'ni ni-watch-time text-blue',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'สถิติ',
            path: '/stats',
            icon: 'ni ni-chart-pie-35 text-yellow',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'ชีทสรุป',
            path: '/sheet',
            icon: 'ni ni-books text-green',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'วีดีโอทบทวน',
            path: '/video',
            icon: 'ni ni-button-play text-green',
          }"
        >
        </sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: 'จดจำ',
            path: '/remember',
            icon: 'ni ni-atom text-green',
          }"
        >
        </sidebar-item> -->

        <!-- <sidebar-item
          :link="{
            name: 'คูปอง',
            path: '/campaign',
            icon: 'ni ni-favourite-28 text-orange',
          }"
        >
        </sidebar-item> -->

        <sidebar-item
          :link="{
            name: 'พรีเมี่ยม',
            path: '/pricing',
            icon: 'ni ni-atom text-pink',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'คำถามยอดฮิต',
            path: '/blogs',
            icon: 'ni ni-air-baloon text-orange',
          }"
        >
        </sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: 'โหลดลงมือถือ',
            path: '/download',
            icon: 'ni ni-mobile-button text-green',
          }"
        >
        </sidebar-item> -->

        <!-- <sidebar-item
                :link="{
                  name: 'Tables',
                  path: '/tables',
                  icon: 'ni ni-bullet-list-67 text-red'
                }">
        </sidebar-item>

        <sidebar-item
                  :link="{
                    name: 'Login',
                    path: '/login',
                    icon: 'ni ni-key-25 text-info'
                  }">
        </sidebar-item>
        <sidebar-item
                  :link="{
                    name: 'Register',
                    path: '/register',
                    icon: 'ni ni-circle-08 text-pink'
                  }">
        </sidebar-item> -->
      </template>

      <template slot="links-after">
        <hr class="my-3" />
        <!-- <h6 class="navbar-heading p-0 text-muted">Documentation</h6>

        <b-nav class="navbar-nav mb-md-3">
          <b-nav-item
            href="https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard"
          >
            <i class="ni ni-spaceship"></i>
            <b-nav-text class="p-0">Getting started</b-nav-text>
          </b-nav-item>
          <b-nav-item
            href="https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard"
          >
            <i class="ni ni-palette"></i>
            <b-nav-text class="p-0">Foundation</b-nav-text>
          </b-nav-item>
          <b-nav-item
            href="https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard"
          >
            <i class="ni ni-ui-04"></i>
            <b-nav-text class="p-0">Components</b-nav-text>
          </b-nav-item>
        </b-nav> -->
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar
        :type="$route.meta.navbarType"
        v-if="$route.name != 'สอบจริง'"
      ></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
// import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    // DashboardContent,
    FadeTransition,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
</style>
